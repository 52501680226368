li.DraftExam {
  -moz-box-shadow: 0 0 5px #888;
  -webkit-box-shadow: 0 0 5px#888;
  box-shadow: 0 0 5px #E1E1E1;
  margin-bottom: 10px;
  padding: 5px 35px 5px 25px;
  border-radius: 7px;
}

span.DraftExamImage {
  width: 100px;
  height: 100px;
  line-height: 40px;
}

span.DraftExamImage img {
  width: 100px;
  height: 100px;
}
section.createExamLayoutHeader {
  max-height: 60vh;
  overflow-y: auto;
}
section.createExamLayout {
  padding: 30px;
  background: #ffffff;
}
