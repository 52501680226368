.login-page {
    min-height: 100vh;
    overflow-y: auto;
}

.login-page #background {
    background-image: url('../assets//image/auth-BG.svg'); 
    background-repeat: no-repeat, repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: clip;
    flex-direction: column;
}

.login-page #background div[class^="circle"] {
    position: absolute;
    aspect-ratio: 1;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}

.login-page #background .circle-1 {
    left: -20px; top: 130px;
    background-color: rgba(240, 248, 255, 0.242);
    height: 200px;
    box-shadow: 0 0px 12px 1px rgba(255, 255, 255, 0.5);
}

.login-page #background .circle-2 {
    left: -10px; top: -30px;
    background-color: rgba(240, 248, 255, 0.242);
    height: 120px;
    box-shadow: 0 3px 8px 0 rgba(255, 255, 255, 0.3);
}

.login-page #background .circle-3 {
    right: -120px; bottom: -150px;
    background-color: rgba(240, 248, 255, 0.2);
    height: 400px;
    box-shadow: 0 -6px 10px 0 rgba(255, 255, 255, 0.3);
}

.login-page .container {
    min-height: max(100vh, 500px);
    position: relative;
    z-index: 5;
    background-color: rgba(250, 252, 252, 0.85);
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: space-between;
}

@media (max-width: 992px) {
    .login-page #background {
        display: none;
    }
}

.login-page .container::after {
    content: ' ';
    background-image: url('../assets//image/auth-BG.svg');
    background-repeat: no-repeat, repeat;
    background-position: center center;
    background-size: cover;
    position: absolute;
    inset: 0;
    z-index: -1;
    display: none;
    filter: blur(3px);
    -webkit-filter: blur(3px);
    opacity: 60%;
}

@media (min-width : 768px) and (max-width: 992px) {
    .login-page .container::after {
        display: block;
    }
}

.login-page .container .frame {
    /* padding-block: 20px;  */
    padding-inline: 8px;
    display: flex; 
    flex-direction: column; 
    height: 500px;
    max-width: 400px; 
    width: 100%; 
    justify-content: center;
}

#root .required-span {
    color: red;
}

#root .top-insert {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 15%;
    padding-top: 5%;
}

.login-page .container .frame > .ant-select {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 100px;
}

.login-page .heading {
    text-align: center;
    margin-bottom: 0.2rem;
}

.login-page .subtitle {
    margin-bottom: 2.2rem;
}

.login-page .input-custom {
    margin-bottom: 14px;
}

.login-page .image-block {
    max-width: 200px;
    margin-inline: auto;
    margin-bottom: 10px;
    text-align: center;
}

.login-page .image-block span {
    color: #3B3CEA;
}

.login-page .link {
    margin-top: -8px;
    margin-bottom: 12px;
}

#logo {
    width: 20%;
    margin-inline: auto;
    margin-bottom: 0.2rem;
}

.login-page footer {
    padding-bottom: 2%;
}
