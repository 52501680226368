li.UpComingExam{
    -moz-box-shadow: 0 0 5px #E1E1E1;
    -webkit-box-shadow: 0 0 5px#E1E1E1;
    box-shadow: 0 0 5px #E1E1E1;
    margin-bottom: 10px;
    padding:5px 35px 5px 25px;
    border-radius: 7px;
    }

li.UpComingExam table{
        width:70%;
    }

span.UpComingExamImage{
    width: 100px;
    height: 100px;
    line-height: 40px;
}

span.UpComingExamImage img{
    width: 100px;
    height: 100px;

}

div.UpComingExamDiv table tr> th{
    border:none;
    padding: 0 0 0 10px;
    text-align: left;
    font-weight: normal;
}

div.UpComingExamDiv table tr> td{
    border:none;
    padding: 0 0 0 10px;
    text-align: left;
    font-weight: bolder;
}

li.UpComingExam h4.ant-list-item-meta-title{
    padding: 0 0 0 10px;
}

li.UpComingExam span.MyExamSpan{
    padding: 0 0 0 10px;
}