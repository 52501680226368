.add-question-component {
  display: flex;
  flex-direction: column;
  background-color: #FAFAFA;
}

.add-question-component > div {
  height: 100%;
}

.add-question-section > .ant-layout {
  position: relative;
}

.add-question-section .add-question-section-header {
  background-color: #FAFAFA;
  padding-block: 0;
}

.add-question-section .has-sidebar {
  flex-direction: row;
}

.add-question-section .slider-trigger-button {
  position: absolute;
  top: 0; right: 24px;
  z-index: 50;
  height: 40px;
  background-color: #1890ff;
}

.add-question-section .add-question-sidebar {
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.add-question-section .add-question-sidebar ul.ant-menu-root {
  border-right: 0;
}

@media (min-width: 992px) {
  .add-question-section .slider-trigger-button {
    display: none;
  }
}