
.ReaarngeButtons input[type=text]{

  width: 100%;
}

.ReaarngeButtons button{
    margin-top: 10px;
  }

  .ReaarngeButtons{
    border-style: none;
    padding: 20px;
    margin-top: 40px;
  }


  .ReaarngeButtons {
    display: flex;
 
  }

  
