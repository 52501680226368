li.DraftExam{
    -moz-box-shadow: 0 0 5px #888;
    -webkit-box-shadow: 0 0 5px#888;
    box-shadow: 0 0 5px #E1E1E1;
    margin-bottom: 10px;
    padding:5px 35px 5px 25px;
    border-radius: 7px;
    }

span.DraftExamImage{
    width: 100px;
    height: 100px;
    line-height: 40px;
}

span.DraftExamImage img{
    width: 100px;
    height: 100px;

}

div.MyExamDiv table tr> th{
    border:none;
    padding: 0 0 0 20px;
    text-align: center;
    font-weight: normal;
}

div.MyExamDiv table tr> td{
    border:none;
    padding: 0 0 0 20px;
    text-align: center;
    font-weight: bolder;
}

li.MyExam h4.ant-list-item-meta-title{
    padding: 0 0 0 10px;
}

li.MyExam span.MyExamSpan{
    padding: 0 0 0 10px;
}