.ReportPlotHeader{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 12px;
    box-sizing: border-box;
    -webkit-box-align: center;
    align-items: center;

}
.ReportPlotSectionHeader{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 12px;
    box-sizing: border-box;
    -webkit-box-align: center;
    align-items: center;
    font-size: 16px;
}
.ReportPlot{
    height: 100%; width: 100%; user-select: none; pointer-events: none; touch-action: none; position: relative;
}

.PlotCardSection{
    min-height: 150px;
    min-width: 300px;
    height: 100%;
    width: 100%;
    padding: 24px 12px;
    box-sizing: border-box;
    grid-column: span 5;
    height: 150px;
    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 230, 230);
    vertical-align: top;
    font-size: 18px;
}

.PlotCard{
    min-height: 150px;
    min-width: 320px;
    height: 100%;
    width: 100%;
    padding: 24px 12px;
    box-sizing: border-box;
    grid-column: span 5;
    height: 350px;
    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 230, 230);
}

PlotCardQuestion{
    min-height: 150px;
    min-width: 220px;
    height: 100%;
    width: 100%;
    padding: 24px 12px;
    box-sizing: border-box;
    grid-column: span 5;
    height: 350px;
    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 230, 230);
}

td.firstColumnReport{
    vertical-align: top;
}
.ReportTabHeader{
    text-align: center;
}
.SectionTabPane{
    padding-top: 30px;
}
.SectionHeaderReport{
    padding-top: 40px;
    -webkit-font-smoothing: antialiased;
    font-size: 21px;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0px;
    text-decoration: inherit;
    text-transform: inherit;
}
.Difficult{
    border: 1px solid rgba(21,15,45,0.12);
    border-radius: 24px;
    width: 100%;
}
.DifficultCard{
    padding:20px;
}
.DifficultCardHeader{
    font-weight: bold;
    font-size: 14px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgb(231, 230, 230);
}

.createExamLayout{
    background-color: #F5F7FA;
}

