.site-layout-content {
  background: #fff;
  padding: 24px;
  min-height: 280px;
}

.SubHeader {
  padding: 0 25px;
  line-height: 64px;
  background: #fff !important;
  border: 1px #282c34;
}

.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 15px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-table-thead>tr>th, .ant-table tfoot>tr>th {
  position: relative;
  padding: 8px 16px;
  overflow-wrap: break-word;
}

.ant-table-tbody>tr>td, .ant-table tfoot>tr>td {
  position: relative;
  padding: 3px 16px;
  overflow-wrap: break-word;
}

.SubSection2 {
  margin: 20px;
}

.ExamSelector {
  text-align: left;
  display: inline-flex;
}