.usercard{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 270px;
    height: 233px;
    background: #FFFFFF;
    border-radius: 2px;
    margin: 10px;
    padding: 20px;
    text-align: center;
}

.belowheader{
    height: 40px;
    background: #FAFAFA;
}

.selector {
    float: right;
  }