li.MyExam{
    -moz-box-shadow: 0 0 5px #888;
    -webkit-box-shadow: 0 0 5px#888;
    box-shadow: 0 0 5px #E1E1E1;
    margin-bottom: 10px;
    padding:5px 35px 5px 25px;
    border-radius: 7px;
    }

li.MyExam table{
        width:80%;
    }

span.MyExamImage{
    width: 100px;
    height: 100px;
    line-height: 40px;
}

span.MyExamImage img{
    width: 100px;
    height: 100px;

}

div.MyExamDiv table tr> th{
    border:none;
    padding: 0 0 0 10px;
    text-align: left;
    font-weight: normal;
}

div.MyExamDiv table tr> td{
    border:none;
    padding: 0 0 0 10px;
    text-align: left;
    font-weight: bolder;
}

li.MyExam h4.ant-list-item-meta-title{
    padding: 0 0 0 10px;
}

li.MyExam span.MyExamSpan{
    padding: 0 0 0 10px;
}

div.PratciceTitleLeft{
    float: left;
}
div.PratciceTitleRight{
    float:right;
}
p.SubjectHeader{
    margin-top: 1em;
    margin-bottom: 1em;
}
div.ColValue{
    border: none;
    padding: 0 0 0 10px;
    text-align: left;
    font-weight: bolder;
} 

div.CardCol{
    padding: 10px;

}
div.CardPractice{
    -moz-box-shadow: 0 0 5px #E1E1E1;
    -webkit-box-shadow: 0 0 5px#E1E1E1;
    box-shadow: 0 0 5px #E1E1E1;
    margin-bottom: 10px;
    padding:5px 35px 5px 25px;
    border-radius: 7px;
}
div.OuterDiv{
    width:100%;
    overflow: auto;
}

div.InnerDiv{
    width: 48%;
    float: left;
    margin: 1%;
    padding: 5px;
}