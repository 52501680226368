
.ReaarngeSubjects input[type=text]{

  width: 100%;
}

.ReaarngeSubjects button{
    margin-top: 10px;
  }

  .ReaarngeSubjects{
    border-style: none;
    padding: 20px;
    margin-top: 40px;
  }


  .ReaarngeSubjects {
    display: flex;
 
  }

  .ArrangeQuestions {
    display: flex;
 
  }

  .ReaarngeButtonTrue {
    display: inline-block;
    font-size: 14px;
    cursor: move;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #1890FF;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: #1890FF;
    padding: 7px;
    margin:5px;
  }

  .sectionStatusPUB {
    display: inline-block;
    font-size: 14px;
    cursor: move;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: rgb(8, 250, 8);
    background-color: white;
    border: 1px solid rgb(8, 250, 8);
    padding: 7px;
    margin:5px;
  }

  .ArrangeSpan {
    display: inline-block;
    font-size: 14px;
    cursor: move;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: rgba(0, 0, 0, 0.5);
    background-color: white;
    padding: 7px;
    margin:5px;
  }
  .ReaarngeButtonTrue :hover{
    display: inline-block;
    font-size: 14px;
    cursor: move;
    text-align: center;
    text-decoration: none;
    outline: none;
    color:rgba(0, 0, 0, 0.5);
    background-color: white;
    padding: 7px;
    margin:5px;
  }
  
