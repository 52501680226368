.logged-in-view {
    height: 100%;
}

.ant-layout-sider .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-top: 0;
    padding-top: 0;
    /* padding-right: 4px; */
}

.ant-layout-sider-children ul.ant-menu-root {
    overflow: overlay;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    border-right: 0;
}

.ant-layout-sider-children ul.ant-menu-root li::after {
    margin-right: 0px;
}

.ant-layout-sider-children ul.ant-menu-root::-webkit-scrollbar {
    display: block;
    width: 6px;
}

.ant-layout-sider-children ul.ant-menu-root::-webkit-scrollbar-thumb {
    background: #0e39976c;
}

.ant-layout-sider-children ul.ant-menu-root > li:last-child {
    margin-bottom: 4rem;
}