li.MyExam{
    -moz-box-shadow: 0 0 5px #888;
    -webkit-box-shadow: 0 0 5px#888;
    box-shadow: 0 0 5px #E1E1E1;
    margin-bottom: 10px;
    padding:5px 35px 5px 25px;
    border-radius: 7px;
    }

li.MyExam table{
        width:80%;
    }

span.MyExamImage{
    width: 100px;
    height: 100px;
    line-height: 40px;
}

span.MyExamImage img{
    width: 100px;
    height: 100px;

}

div.MyExamDiv table tr> th{
    border:none;
    padding: 0 0 0 10px;
    text-align: left;
    font-weight: normal;
}

div.MyExamDiv table tr> td{
    border:none;
    padding: 0 0 0 10px;
    text-align: left;
    font-weight: bolder;
}

li.MyExam h4.ant-list-item-meta-title{
    padding: 0 0 0 10px;
}

li.MyExam span.MyExamSpan{
    padding: 0 0 0 10px;
}

.ExamNameHeader {
    font-size: 18pt;
    float: left;
    color: white;
}
.ExamTimeHeader{
    font-size: 16pt;
    float: right;
    color: white;
}

.ExamTimeHeader div{
    font-size: 13pt;
    float:none;
    color: white;
}
.MainExamLayout{
    flex-direction: row;
}
.createExamLayoutLeft{
    display: flex;
    flex: auto;
    flex-direction: column;
    background: #ffffff !important;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 230, 230);
    padding: 10px;
    width:70%;
}
.createExamLayoutRight{
    display: flex;
    flex: auto;
    flex-direction: column;
    background: #ffffff !important;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 230, 230);
    padding: 10px;
    position:sticky;
    position: -webkit-sticky;
    top:0
}

.StatusLabel{
    padding: 5px;
}
.ExamAvatar{
    padding: 25px 0px 25px 0px;
}
.ExamStatusTable td{
    padding: 5px;
}
div.AttemptQuestionGrid{
    grid-template-columns: repeat(auto-fit, minmax(221px, 1fr));
    display: grid;
    margin-left: 0px!important; 
    margin-right: 0px!important;
}

div.AttemptQuestionGrid > div.ant-col{
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.QuestionCard{
    height: 336px;
    margin: 12px;
    max-width: 282px;
    float: left;
    border-radius: 16px;
    cursor: pointer;
    color:#150F2D;
    font-size: 13px;
    text-overflow: ellipsis;
    width:90%;
    box-shadow: 0 0 5px #e1e1e1;
}
.QuestionCard div.ant-card-head{
    border-bottom:none;
    padding: 0 10px;
}
.QuestionCard  div.ant-card-head div.ant-card-head-wrapper   .ant-card-head-title
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.QuestionCard div.ant-card-body{
    height: 67%;
    padding:16px;
}
.QuestionCard ul.ant-card-actions{
    background: none;
    border-top:none;
    width: max-content;
    background-color: #EAECF1;
    height: 20px;
    padding: 1px 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 4px;
    float: right;
    margin-right: 5px;
}
.QuestionCard ul.ant-card-actions > li > span{
    font-size: 12px;
}
.QuestionCard ul.ant-card-actions > li:not(:last-child) {
    border-right: none;
}
.Options{
    margin-left:13px;
}

.QuesttionStatusNotVisited{
    background-color: #F0F0F0
}

.QuesttionStatusNotAnswered{
    background-color: #FED911
}
.QuesttionStatusAnswered{
    background-color: #54C51D
}
.QuesttionStatusReview{
    background-color: #A028FF
}
.QuesttionStatusAnsweredReview{
    background-color: #FFA500
}

.QuesttionStatusNotVisitedCard{
    border-color: #F0F0F0
}

.QuesttionStatusNotAnsweredCard{
    border-color: #FED911
}
.QuesttionStatusAnsweredCard{
    border-color: #54C51D
}
.QuesttionStatusReviewCard{
    border-color: #A028FF
}
.QuesttionStatusAnsweredReviewCard{
    border-color: #FFA500
}

div.QuestionStatusHeaderIcon{
    height: 8px;
    width: 8px;
    border-radius: 274.76px;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
}

div.HeaderQuestionStatusLabel{
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    line-height: 1.125rem;
    font-weight: 500;
    letter-spacing: .25px;
    text-decoration: none;
    text-transform: none;
}

.QuesttionStatusNotVisitedTitle{
    color: #F0F0F0
}

.QuesttionStatusNotAnsweredTitle{
    color: #FED911
}
.QuesttionStatusAnsweredTitle{
    color: #54C51D
}
.QuesttionStatusReviewTitle{
    color: #A028FF
}
.QuesttionStatusAnsweredReviewTitle{
    color: #FFA500
}

.Outline{    display: flex;
justify-content: flex-end;
align-items: center;
width: 77%;
font-size: 14px;
}
div.CardFlipDiv{
    float: left; 
}
.createExamLayoutHeader{
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 10vh;
    background: #fafafa !important;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 230, 230);
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
  }
  .site-layout-background{
    background: #fafafa !important;
  }

