
.app-header {
  display: flex;
  align-items: center;
}

.app-header .left {
  flex-grow: 1;
}

.app-header .left .ant-breadcrumb {
  height: 100%;
  font-size: 1.1rem;
}

.app-header .left .ant-breadcrumb > span {
  display: inline-flex;
  gap: 10px;
  margin-right: 10px;
}

.app-header .right {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}


