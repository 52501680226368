.ReaarngeButtons input[type=text] {

  width: 100%;
}

.ReaarngeButtons button {
  margin-top: 10px;
}

.ReaarngeButtons {
  border-style: none;
  padding: 0px 20px;
  margin-top: 40px;
  display: flex;
}

table.ReviewTable {
  border: 1px solid #f0f0f0;
  text-align: left;
}

table.ReviewTable tr>td, th {
  border: 1px solid #f0f0f0;
  padding: 10px;
  text-align: left;
}