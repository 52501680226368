.sidebar {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  overflow: hidden;
}

.sidebar .logo {
  /* background-color: #fff; */
  display: flex;
  justify-content: flex-start;
}

.sidebar .logo a {
  height: 64px;
}