
.SelectSubjects input[type=text]{

  width: 100%;
}

.SelectSubjects button{
    margin-top: 0;
  }

#root .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}

  /* .SelectSubjects .ant-upload-list-item-info {
    display: none;
  } */

  .SelectSubjects{
    border-style: none;
    padding: 20px;
    margin-top: 40px;
  }

  .exam {
    background: #F7FDFF;
  }

  .examName {
    display: inline-block;
    font-size: 16px;
    padding: 0 15px;
  }
  span.examNameSpan{
    font-weight: bold;
  }
  .examTable{
    width: 100%;
  }
  .examNameOuter{
    display: flex;
    padding: 5px;
  }

  .examNameEdit{
    text-align:right;
    padding: 0 25px;
  }

  .subjectButtons {
    display: flex;
 
  }

  .ManageExamSpace{
    width: 70%;
  }

  .subjectButtonTrue {
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #1890FF;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: #1890FF;
  }
  .subjectButtonTrue :hover{
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #1890FF;
    background-color: white;

  }
  
  .subjectButtonFalse {
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #cccccc;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
  }

  .subjectButtonFalse :hover{
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #cccccc;
    background-color: white;

  }