
.createExamName input[type=text]{

  width: 100%;
}

.createExamName button{
    margin-top: 10px;
  }

  .createExamName{
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 230, 230);
    padding: 20px;
    margin-top: 40px;
  }

  .createExamLayoutHeader{
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 10vh;
    background: #fafafa !important;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 230, 230);
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
  }

  .createExamLayout{
    display: flex;
    flex: auto;
    flex-direction: column;
    background: #ffffff !important;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 230, 230);
    padding: 10px;
  }
  .site-layout-background{
    background: #fafafa !important;
  }