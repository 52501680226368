.all-questions-section .selector-section {
   background-color: white;
   border-radius: 10px;
   margin-inline: 14px;
   margin-block: 2px;
   display: flex;
   flex-wrap: wrap;
   column-gap: 40px;
   row-gap: 12px;
   /* justify-content: space-around; */
   padding-block: 10px;
   padding-inline: 15px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

.all-questions-section .selector-section .left-side {
   display: flex;
   column-gap: 10px;
}

.all-questions-section .selector-section .right-side {
   margin-left: auto;
}

.list-questions-section>div {
   padding-inline: 14px;
   overflow-y: auto;
}

.list-questions-section .ant-table th.ant-table-selection-column {
   width: 70px;
}

.draft-questions-section .ant-table th.ant-table-selection-column {
   width: 70px;
}

.list-questions-section .ant-table-row td.ant-table-cell:nth-child(6) {
   /* this has to same as all other row height in the table as mentioned in App.less */
   /* height: 50px; */
   padding: 0px 40px;
   /* display: flex;
   justify-content: center;
   align-items: center; */
}

.list-questions-section>div .ant-pagination {
   margin-block: 16px;
}

.list-questions-section .pagination-row {
   display: flex;
   justify-content: flex-end;
}

/* Special case for draft question */
.draft-questions-section>div .ant-pagination {
   margin-block: 16px;
}

.draft-questions-section>div {
   padding-inline: 14px;
   overflow-y: auto;
}

.draft-questions-section .pagination-row {
   display: flex;
   justify-content: flex-end;
}

.draft-questions-section .ant-table-row td.ant-table-cell:nth-child(5) {
   /* this has to same as all other row height in the table as mentioned in App.less */
   /* height: 50px; */
   padding: 0 40px;
   /* display: flex; */
   /* justify-content: center; */
   /* align-items: center; */
}
