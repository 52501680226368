section.upload-content {
	padding-inline: 15px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

section.upload-content > span:first-child {
	flex: 1 0 120px;
	display: flex;

}

section.upload-content span .anticon-inbox {
	justify-content: center;
}

section.upload-content button {
	flex: 0.2 0 30px;
}

section.upload-content button span{
	justify-content: center;
}

section.upload-content .questions-list {
	overflow-y: auto;
	flex: auto 0 400px;
	scrollbar-width: none;
}

.question-block {
	padding-block: 1rem;
	display: flex;
	flex-wrap: wrap;
}

.questions-list div:not(:last-child) form {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

section.upload-content .question-block button {
	margin-top: 12px;
	flex: 1 0 200px;
}

.question-block .question {
	width: 100%;
	/* margin-bottom: 12px; */
}

.question-block h4, .question-block h5 {
	width: 100%;
	margin-top: 10px;
}

.question-block .ant-input[disabled] {
	color: rgba(0, 0, 0, 0.8);
}

.question-block .option {
	/* width: 50%; */
	margin-block: 3px;
	margin-inline: 2px;
	flex: 0.5 0 48%;
}

.question-block .complexity-radio-btn {
	display: flex;
	width: 100%;
}