.selector {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 15px;

}

.selector > div {
 /* width: 100px;*/
  text-align: center;
  height: fit-content;
  /* line-height: 75px; */
  /*font-size: 30px;*/
}

.selectorLeftButton a.ant-btn{
  border-right-color: white;
  line-height: 1.5715;
}