
  .QuestionHeader{
    background-color: #FAFAFA;
    border-color: rgb(231, 230, 230);
    padding: 10px;
  }
  .QuestionContent{
    padding: 20px;
  }
  .QuestionOtherContent{
    padding: 20px;
  }
  .QuestionPanel{
    border: 1px solid #ccc;
  }
  .ant-page-header {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    padding: 8px 20px;
  }
  
  .MCQ .ant-avatar-string{
    line-height: 26px !important;
  }
  
  .optionsDiv{
    display:grid;
  }
  
  .optionsDiv div.ant-space-align-baseline{
    align-items: inherit;
  }
  
  .AddOptionButton{
    align-content: right;
  }
  .MCQ .ant-avatar {
        width: 27px !important;
      height: 27px !important;
      line-height: 24px !important;
      font-size: 16px !important;
      cursor: pointer !important;
      margin-bottom: 12px !important;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.65);
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      -webkit-font-feature-settings: 'tnum';
      font-feature-settings: 'tnum', "tnum";
      position: relative;
      display: inline-block;
      overflow: hidden;
      color: #fff;
      white-space: nowrap;
      text-align: center;
      vertical-align: middle;
      background: #ccc;
      border-radius: 1%;
  }
  
  .MCQ .radioSelected span {
    width: 27px !important;
    height: 27px !important;
    line-height: 24px !important;
    font-size: 16px !important;
    cursor: pointer !important;
    margin-bottom: 10px !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 1px;
    padding: 0;
    background: #1890ff !important;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    border-radius: 1%;
  }
  
  
  .MCQ span.ant-radio + * {
    padding-right: 8px;
    padding-left: 0px; 
  }
  .MCQ  textarea.ant-input {
    max-width: 100%;
    margin-bottom: 3.5px;
    height: 27px;
    min-height: 27px;
    line-height: 1;
    vertical-align: bottom;
    -webkit-transition: all 0.3s, height 0s;
    transition: all 0.3s, height 0s;
  
  }
  
  .MCQ .ant-radio-checked {
    background-color: #1890ff !important;
  }
  
  .MCQ  .ant-radio {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #ccc;
    background-color: #ccc;
    margin: 0;
    padding: 0;
    color: grey;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    top: 0px;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: sub;
    outline: none;
    cursor: pointer;
  }
  
  .MCQ .ant-radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-color: #ccc;
    border-style: solid;
    border-width: 1px;
    margin: 8px;
    border-radius: 100px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  
  .MCQ .ant-radio-inner::after {
    visibility: hidden;
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #ccc;
    border-color: #ccc;
    border-style: solid;
    border-width: 1px;
    margin: 10px;
    border-radius: 100px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .MCQ .ant-radio-checked .ant-radio-inner::after {
    visibility: hidden;
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #1890ff !important;
    border-color:  #1890ff !important;
    border-style: solid;
    border-width: 1px;
    margin: 10px;
    border-radius: 100px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  div.MCQ{
    width:fit-content;
  
  }
  
  .MCQ textarea.ant-input{
    width: 300px !important;
  }
  
  .MCQ .AddOptionButton .ant-btn{
    padding:2px 10px;
    font-weight: 50;
    text-align: right;
  }
  .MCQ .AddOptionButton{
  text-align: right;
  }
  
  .ButtonPanel{
    text-align: right;
    padding:16px;
    }
  .QuestionOtherContent .ant-radio-group{
    padding: 10px 0 !important;
  }
  h1.Question{
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", "tnum";
    position: relative;
    padding: 10px 0px;
  }
  